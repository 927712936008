import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import { Row, Col, Card, Table, Badge, Tabs, Tab, ButtonGroup, ToggleButton, FormLabel, FormControl, Pagination, DropdownButton, Dropdown, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import "react-datetime/css/react-datetime.css";
import { portalmetrics, releasemetrics, jobs, getLevelQuartile, getUserSessions, getLevelBarChart, getPageMetricsData, getPageMetricsDataCount, getSlowPages } from "../service/getdata";
import ApexChart from "react-apexcharts";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { _SsrmInfiniteSharedApiModule } from "ag-grid-community";
import exportFile from "../service/exportFile";

function Dashboard(props) {
  const [portal, setportal] = useState([]);
  const [release, setrelease] = useState([]);
  const [jobsdata, setjobsdata] = useState([]);
  const [levelwise, setlevelwise] = useState([]);
  const [performanceData, setPerformanceData] = useState([])
  const [durationData, setDurationData] = useState([])
  const [userSessionsData, setUserSessionsData] = useState([])
  const [metricsData, setMetricsData] = useState([]);
  const [slowPagesData, setSlowPagesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [prod, setprod] = useState(false);
  const [uat, setuat] = useState(true);
  const [fromdt, setfromdt] = useState("");
  const [todt, settodt] = useState("");
  const [oneday, setoneday] = useState(false);
  const [sevenday, setsevenday] = useState(false);
  const [searchspec, setsearchspec] = useState({});
  const [gridsearchspec, setgridsearchspec] = useState({});
  const [apply, setapply] = useState(false);
  const [exportCompleted, setExportCompleted] = useState(true);

  const totalPages = Math.ceil(totalCount / pageSize);

  const onFilterChanged = (event) => {
    var fm = (event?.api.getFilterModel())
    var keys = Object.keys(fm)
    var ss = []
    var _searchspec = ""
    for (var i = 0; i < keys.length; i++) {
      ss.push('gtslogs.' + keys[i] + " like '%" + fm[keys[i]]?.filter + "%'")
    }

    for (var j = 0; j < ss.length; j++) {
      if (j === ss.length - 1) {
        _searchspec = _searchspec + ss[j]
      }
      else {
        _searchspec = _searchspec + ss[j] + " AND "
      }
    }
    var gss = {}
    gss["gridsearchspec"] = _searchspec
    setgridsearchspec(encodeURI(JSON.stringify(gss)))
  };

  const onclear = () => {
    setapply(false)
    setoneday(false)
    setsevenday(false)
    setfromdt("")
    settodt("")
    setsearchspec({})
  }

  const onapply = () => {
    setapply(true)
    if ((fromdt == undefined || fromdt == "") && todt !== undefined && todt !== "") {
      alert("Enter From Date");
    }
    if (fromdt !== "" && fromdt !== undefined && (todt == undefined || todt == "")) {
      alert("Enter To Date");
    }
    if (new Date(fromdt).getTime() > new Date(todt).getTime()) {
      alert("Please Check Dates")
    }
    var ss = {}
    if (fromdt !== "" && fromdt !== undefined) {
      ss["fromdt"] = new Date(fromdt).toISOString()
    }
    if (todt !== "" && todt !== undefined) {
      ss["todt"] = new Date(todt).toISOString()
    }

    if (oneday) {
      var dt = new Date()
      ss["todt"] = new Date(dt).toISOString()
      dt.setDate(dt.getDate() - 1)
      ss["fromdt"] = new Date(dt).toISOString()
    }

    if (sevenday) {
      var dt = new Date()
      ss["todt"] = new Date(dt).toISOString()

      dt.setDate(dt.getDate() - 7)
      ss["fromdt"] = new Date(dt).toISOString()
    }
    setsearchspec(JSON.stringify(ss))

  }

  const ononeday = () => {
    setoneday(true)
    setsevenday(false)
  }

  const onsevenday = () => {
    setoneday(false)
    setsevenday(true)
  }

  const onprodclick = () => {
    setprod(true)
    setuat(false)
  }

  const onuatclick = () => {
    setprod(false)
    setuat(true)
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1); // Reset to first page when page size changes
  };

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    {
      field: "__time", headerName: "Timestamp", filter: true, filterParams: {
        buttons: ["reset", "apply"],
      },
    },
    {
      field: "dt", headerName: "Date", filter: true, filterParams: {
        buttons: ["reset", "apply"],
      },
    },
    {
      field: "level", headerName: "Level", filter: true, filterParams: {
        buttons: ["reset", "apply"],
      },
    },
    {
      field: "pagename", headerName: "Page Name", filter: true, filterParams: {
        buttons: ["reset", "apply"],
      },
    },
    {
      field: "submenu", headerName: "Submenu", filter: true, filterParams: {
        buttons: ["reset", "apply"],
      },
    },
    {
      field: "DurationMs", headerName: "Duration", filter: true, filterParams: {
        buttons: ["reset", "apply"],
      },
    },
    {
      field: "tabname", headerName: "Tab Name", filter: true, filterParams: {
        buttons: ["reset", "apply"],
      },
    },
    {
      field: "avgduration", headerName: "Average Duration", filter: true, filterParams: {
        buttons: ["reset", "apply"],
      },
    },
    {
      field: "UserAuthenticatedId", headerName: "User", filter: true, filterParams: {
        buttons: ["reset", "apply"],
      },
    }
    ,
    {
      field: "Url", headerName: "Url", filter: true, filterParams: {
        buttons: ["reset", "apply"],
      },
    }
    ,
    {
      field: "entityid", headerName: "Entity Id", filter: true, filterParams: {
        buttons: ["reset", "apply"],
      },
    }
    
  ]);

  const slowPagesColDefs = useMemo(() => {
    return [
      {
        field: "level", headerName: "Level", filter: true, filterParams: {
          buttons: ["reset", "apply"],
        },
      },
      {
        field: "pagename", headerName: "Page Name", filter: true, filterParams: {
          buttons: ["reset", "apply"],
        },
      },
      {
        field: "submenu", headerName: "Submenu", filter: true, filterParams: {
          buttons: ["reset", "apply"],
        },
      },
      {
        field: "tabname", headerName: "Tab Name", filter: true, filterParams: {
          buttons: ["reset", "apply"],
        },
      },
      {
        field: "entityid", headerName: "Entity Id", filter: true, filterParams: {
          buttons: ["reset", "apply"],
        },
      },
      {
        field: "Url", headerName: "Url", filter: true, filterParams: {
          buttons: ["reset", "apply"],
        }, cellRenderer: (params) => {
          return <a href={params.value} target="_blank">{params.value}</a>;
        }
      },
      {
        field: "Maximum", headerName: "Maximum", filter: true, filterParams: {
          buttons: ["reset", "apply"],
        },
      },
      {
        field: "Minimum", headerName: "Minimum", filter: true, filterParams: {
          buttons: ["reset", "apply"],
        },
      },
      {
        field: "Average", headerName: "Average", filter: true, filterParams: {
          buttons: ["reset", "apply"],
        }, cellRenderer: (params) => {
          return parseFloat(params.value).toFixed(2);
        }
      },
      {
        field: "hits", headerName: "Pagehits", filter: true, filterParams: {
          buttons: ["reset", "apply"],
        }, cellRenderer: (params) => {
          return parseFloat(params.value).toFixed(2);
        }
      }
    ]
  }, [])

  useEffect(() => {
    var datasource = prod ? "1" : "0"
    getLevelQuartile(datasource, searchspec).then((data) => {
      if (data.length > 0) {
        let finalData = {}
        data.shift();
        for (let item of data) {
          let key = item[0].split("-")[1]
          if (finalData[key]) {
            finalData[key].push(item[3])
          } else {
            finalData[key] = [item[3]]
          }

          if (finalData[key].length === 4) {
            finalData[key].push(item[2])
          }
        }
        setPerformanceData(finalData);
      }
    }).catch((err) => {
      console.error(err)
    });

    getLevelBarChart(datasource, searchspec).then((data) => {
      if (data.length > 0) {
        data.shift()
        setDurationData(data);
      }
    }).catch((err) => {
      console.error(err)
    });

    getUserSessions(datasource, searchspec).then((data) => {
      if (data.length > 0) {
        data.shift()
        setUserSessionsData(data);
      }
    }).catch((err) => {
      console.error(err)
    });

    getSlowPages(datasource, searchspec).then((data) => {
      if (data.length > 0) {
        setSlowPagesData(data);
      }
    }).catch((err) => {
      console.error(err)
    });
  }, [prod, uat, searchspec])

  useEffect(() => {
    var datasource = prod ? "1" : "0"
    getPageMetricsData(((currentPage - 1) * pageSize), pageSize, datasource, searchspec, gridsearchspec).then((data) => {
      setMetricsData(data || []);
    }).catch((err) => {
      console.error(err)
    });
    getPageMetricsDataCount(datasource, searchspec, gridsearchspec).then((data) => {
      setTotalCount(data || 0);
    }).catch((err) => {
      console.error(err)
    });
  }, [currentPage, pageSize, prod, uat, searchspec, gridsearchspec])

  const boxPlotOptions = useMemo(() => {
    return {
      series: [
        {
          name: 'box',
          type: 'boxPlot',
          data: Object.entries(performanceData).map(item => ({ x: item[0], y: item[1].reverse() }))
        },
      ],
      options: {
        colors: ['#008FFB', '#FEB019'],
        xaxis: {
          type: 'category',
        }
      },
    }
  }, [performanceData])

  const durationPlotOptions = useMemo(() => {
    return {
      options: {
        chart: {
          type: 'bar',
          fontFamily: 'Titillium Web, sans-serif',
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },

        xaxis: {
          categories: durationData.map(item => item[0].split("-")[1])
        }
      },
      series: [
        {
          name: "level",
          data: durationData.map(item => item[1])
        }
      ]
    }
  }, [durationData])

  const sessionsPlotOptions = useMemo(() => {
    return {
      options: {
        xaxis: {
          categories: userSessionsData.map(item => item[0])
        },
        stroke: {
          curve: 'smooth',
          width: 1.5
        },
        markers: {
          size: 1,
          strokeColors: '#00ff00',
        }
      },
      series: [
        {
          name: "level",
          data: userSessionsData.map(item => item[1])
        }
      ]
    }
  }, [userSessionsData])


  const findlatesttimestampjob = (datum) => {
    let dates = jobsdata.filter((a) => a.rowKey.indexOf(datum) >= 0).map((b) => new Date(b.timestamp))
    return new Date(Math.max.apply(null, dates)).toLocaleString();
  }


  useEffect(() => {
    jobs().then((d) => {
      //console.log(db)
      setjobsdata(JSON.parse(JSON.stringify(d?.data)))

    });
  }, [jobsdata.length])

  useEffect(() => {
    jobs().then((d) => {
      //console.log(db)
      setjobsdata(JSON.parse(JSON.stringify(d?.data)))

    });
  }, [jobsdata.length])

  useEffect(() => {
    portalmetrics().then((d) => {
      //console.log(iot)
      setportal(d?.data)

    });
  }, [portal.length])

  useEffect(() => {
    releasemetrics().then((d) => {
      //console.log(iot)
      setrelease(d?.data)
    });
  }, [release.length])

  const downloadBlob = async () => {
    setExportCompleted(false)
    const exportPageSize = 500;
    const totalAPICalls = Math.ceil(totalCount / exportPageSize);
    var datasource = prod ? "1" : "0"
    let downloadset = [];
    try {
      for (var i = 0; i < totalAPICalls; i++) {
        var recordset = await getPageMetricsData(i * exportPageSize, exportPageSize, datasource, searchspec, gridsearchspec)
        recordset?.forEach((item) => {
          downloadset.push(item)
        })
      }

      if (downloadset?.length > 0) {
        exportFile(downloadset, "PageMetricsData");
      }
    } catch (error) {
      console.error(error)
    } finally {
      setExportCompleted(true);
    }
  };

  const downloadSlowPagesBlob = () => {
    setExportCompleted(false)

    if (slowPagesData?.length > 0) {
      exportFile(slowPagesData, "SlowPagesData");
    }
    setExportCompleted(true);
  };

  return (
    <>
      <Row>
        <Col className="m-1">
          <ButtonGroup>
            <ToggleButton
              //className="od-font-sm od-table od-btn-smaller"
              variant="outline-primary"
              size="sm"
              onClick={onprodclick}
              type="checkbox"
              checked={prod}
            >
              PROD
            </ToggleButton>
            <ToggleButton
              //className="od-font-sm od-table od-btn-smaller"
              variant="outline-primary"
              size="sm"
              onClick={onuatclick}
              type="checkbox"
              checked={uat}
            >
              &nbsp;UAT
            </ToggleButton>
          </ButtonGroup>
        </Col>
        <Col md="1" className="m-2"><span style={{ "float": "right", "verticalAlign": "middle", "fontfamily": "Titillium Web" }}>From</span></Col>
        <Col md="2" className="m-1">
          <FormControl type="datetime-local" value={fromdt} onChange={(e) => { setfromdt(e.target.value); setoneday(false); setsevenday(false); }} />
        </Col>
        <Col md="1" className="m-2 "><span style={{ "float": "right", "verticalAlign": "middle", "fontfamily": "Titillium Web" }} >To</span></Col>
        <Col md="2" className="m-1">
          <FormControl type="datetime-local" value={todt} onChange={(e) => { settodt(e.target.value); setoneday(false); setsevenday(false); }} />
        </Col>
        <Col md="2" className="m-1">
          <ButtonGroup style={{ "float": "right" }}>
            <ToggleButton
              //className="od-font-sm od-table od-btn-smaller"
              variant="outline-primary"
              size="sm"
              onClick={ononeday}
              type="checkbox"
              checked={oneday}
            >
              24-Hrs
            </ToggleButton>
            <ToggleButton
              //className="od-font-sm od-table od-btn-smaller"
              variant="outline-primary"
              size="sm"
              onClick={onsevenday}
              type="checkbox"
              checked={sevenday}
            >
              7-Days
            </ToggleButton>
          </ButtonGroup>

        </Col>
        <Col className="m-1">
          <ButtonGroup style={{ "float": "right" }}>
            <ToggleButton
              //className="od-font-sm od-table od-btn-smaller"
              variant="outline-primary"
              size="sm"
              onClick={onapply}
              type="checkbox"
              checked={apply}
              style={{ "float": "right" }}
            >
              Apply
            </ToggleButton>
            <ToggleButton
              //className="od-font-sm od-table od-btn-smaller"
              variant="outline-primary"
              size="sm"
              onClick={onclear}
              type="checkbox"
              checked={!apply}
              style={{ "float": "right" }}
            >
              Clear
            </ToggleButton>
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col className="m-1">
          <Tabs defaultActiveKey="snapshot" id="performancemetrics" className="mb-1 gtstabs" /*variant="pills"*/>
            <Tab eventKey="snapshot" title="Snapshot">
              <>
                <Row>
                  <Col className="m-0" xs={12} sm={6} lg={4}>
                    <Card style={{ height: "500px" }}>
                      <Card.Header>
                        <strong>Levelwise Performance Range-seconds</strong>
                      </Card.Header>
                      <Card.Body className="m-0">
                        <ApexChart
                          options={boxPlotOptions.options}
                          series={boxPlotOptions.series}
                          type="boxPlot"
                        />
                        <Table className='table-sm table-striped od-font-sm'>
                          <thead>
                            <tr>
                              <th className='small'>Level</th>
                              <th className='small'>Minimum</th>
                              <th className='small'>Q1</th>
                              <th className='small'>Median</th>
                              <th className='small'>Q3</th>
                              <th className='small'>Maximum</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              Object.keys(performanceData).map((key, id) => {
                                return <tr key={id}>
                                  <td className='small'>{key}</td>
                                  {
                                    performanceData[key].map((item, idx) => {
                                      return <React.Fragment key={idx}>
                                        <td className='small'>{item}</td>
                                      </React.Fragment>
                                    })
                                  }
                                </tr>
                              })
                            }
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={12} sm={6} lg={4}>
                    <Card style={{ height: "500px" }}>
                      <Card.Header>
                        <strong>Levelwise Avg Duration-seconds</strong>
                      </Card.Header>
                      <Card.Body>
                        <ApexChart
                          options={durationPlotOptions.options}
                          series={durationPlotOptions.series}
                          type="bar"
                        />
                        <Table className='table-sm table-striped od-font-sm'>
                          <thead>
                            <tr>
                              <th className='small'>Level</th>
                              <th className='small'>Avg Duration Ms</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              durationData.map((item, id) => {
                                return <tr key={id}>
                                  <td className='small'>{item[0].split("-")[1]}</td>
                                  <td className='small'>{parseFloat(item[1]).toFixed(2)}</td>
                                </tr>
                              })
                            }
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={12} sm={6} lg={4}>
                    <Card style={{ height: "500px" }}>
                      <Card.Header >
                        <strong>Session Counts</strong>
                      </Card.Header>
                      <Card.Body style={{ overflowY: "auto" }}>
                        <ApexChart
                          options={sessionsPlotOptions.options}
                          series={sessionsPlotOptions.series}
                          type="line"
                        />
                        <div style={{ overflowY: "auto" }}>
                          <Table className='table-sm table-striped od-font-sm'>
                            <thead>
                              <tr>
                                <th className='small'>Date</th>
                                <th className='small'>Sessions</th>
                              </tr>
                            </thead>
                            <tbody >
                              {
                                userSessionsData.map((item, id) => {
                                  return <tr key={id}>
                                    <td className='small'>{item[0]}</td>
                                    <td className='small'>{item[1]}</td>
                                  </tr>
                                })
                              }
                            </tbody>
                          </Table>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            </Tab>
            <Tab eventKey="slowPages" title="Page Performance">
              <>
                <Row className="mt-1">
                  <Col>
                    <Card>
                      <Card.Header className="text-center text-bold d-flex align-items-center justify-content-between">
                        <label style={{ "float": "left" }}>Slow Pages Metrics</label>
                        <Button style={{ "float": "right" }} size="sm" onClick={downloadSlowPagesBlob} disabled={!exportCompleted || !slowPagesData.length}>{exportCompleted ? "Export" : "Exporting..."}</Button>
                      </Card.Header>
                      <Card.Body className="m-1 p-0">
                        <div
                          fontFamily="Titillium Web"
                          className="ag-theme-quartz"
                          style={{ height: "70vh" }}
                        >
                          <AgGridReact
                            rowData={slowPagesData}
                            columnDefs={slowPagesColDefs}
                            defaultColDef={{ flex: 1 }}
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            </Tab>
            <Tab eventKey="details" title="Page Performance Details">
              <>
                <Row className="mt-1">
                  <Col>
                    <Card>
                      <Card.Header className="text-center text-bold d-flex align-items-center justify-content-between">
                        <label style={{ "float": "left" }}>Page Metrics</label>
                        <Button style={{ "float": "right" }} size="sm" onClick={downloadBlob} disabled={!exportCompleted}>{exportCompleted ? "Export" : "Exporting..."}</Button>
                      </Card.Header>
                      <Card.Body className="m-1 p-0">
                        <div
                          fontFamily="Titillium Web"
                          className="ag-theme-quartz" // applying the Data Grid theme
                          style={{ height: "65vh" }} // the Data Grid will fill the size of the parent container
                        >
                          <AgGridReact
                            rowData={metricsData}
                            columnDefs={colDefs}
                            defaultColDef={{ flex: 1 }}
                            onFilterChanged={onFilterChanged}
                          />
                          {totalPages > 1 && (
                            <div className="d-flex justify-content-end align-items-center my-3 gap-3">
                              <span>Page Size:</span>
                              <DropdownButton
                                id="page-size-dropdown"
                                title={`${pageSize}`}
                                variant="outline-primary"
                                onSelect={(e) => handlePageSizeChange(Number(e))}
                              >
                                <Dropdown.Item eventKey="10">10</Dropdown.Item>
                                <Dropdown.Item eventKey="20">20</Dropdown.Item>
                                <Dropdown.Item eventKey="30">30</Dropdown.Item>
                              </DropdownButton>
                              <Pagination className="align-items-center mb-0">
                                <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                                <span className="mx-2">Page <b className="text-primary">{currentPage}</b> of <b className="text-primary">{totalPages}</b></span>
                                <span className="mx-2"><b>Total Records</b> <b className="text-primary">{totalCount}</b></span>
                                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                                <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                              </Pagination>
                            </div>
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            </Tab>
          </Tabs>

        </Col>
      </Row>
      {/* <Row>
        <Col className="m-1">
          <Card style={{ height: 375 }}>
            <Card.Header >
              <strong>Portal Health</strong>
              {
                ((new Date().getTime() - new Date(findlatesttimestampjob("portal")).getTime()) > 300000)
                  ?
                  <Badge pill style={{ float: 'right' }} bg="warning">
                    {
                      findlatesttimestampjob("portal")
                    }
                  </Badge>
                  :
                  <Badge pill style={{ float: 'right' }} bg="success">
                    {
                      findlatesttimestampjob("portal")
                    }
                  </Badge>
              }
            </Card.Header>
            <Card.Body>
              <Table className='table-sm table-striped od-font-sm'>
                <thead>
                  <tr>
                    <th className='small'>Portal</th>
                    <th className='small'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    portal.map((item, id) => {
                      return <tr key={id}>
                        <td className='small'>{item.rowKey}</td>
                        <td className='small'>
                          <FontAwesomeIcon icon={faCircle} className={item.status ? 'text-success' : 'text-danger'} />
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col className="m-1">
          <Card style={{ height: 375 }} >
            <Card.Header>
              <strong>Build & Release</strong>
              {
                ((new Date().getTime() - new Date(findlatesttimestampjob("release")).getTime()) > 300000)
                  ?
                  <Badge pill style={{ float: 'right' }} bg="warning">
                    {
                      findlatesttimestampjob("release")
                    }
                  </Badge>
                  :
                  <Badge pill style={{ float: 'right' }} bg="success">
                    {
                      findlatesttimestampjob("release")
                    }
                  </Badge>
              }
            </Card.Header>
            <Card.Body>
              <Table className='table-sm table-striped od-font-sm'>
                <thead>
                  <tr>
                    <th className='small'>Area</th>
                    <th className='small'>Branch</th>
                    <th className='small'>Version</th>
                    <th className='small'>Date &#40;Local Time&#41;</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    release.map((item, id) => {
                      return <tr key={id}>
                        <td className='small'>{item.rowKey}</td>
                        <td className='small'>{item.branch}</td>
                        <td className='small'>{item.version}</td>
                        <td className='small'>{new Date(item.date).toLocaleString()}</td>
                      </tr>
                    })
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
    </>
  );
}

export default Dashboard;

import ExcelJS from "exceljs";
const blobType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

const exportFile = (downloadSet, fileName) => {
    const workbook = new ExcelJS.Workbook();
    const ws = workbook.addWorksheet("My Sheet");
    var columns = [];
    Object.keys(downloadSet[0]).forEach((item) => {
        columns.push({ header: item, key: item, width: 15 });
    });
    ws.columns = columns;
    ws.addRows(downloadSet);
    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: blobType });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${fileName}.xlsx`);
        link.style.position = "absolute";
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
};

export default exportFile;
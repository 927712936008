import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { useState, useEffect } from "react";
import {
  Popover,
  OverlayTrigger,
  ListGroup,
  Button,
  Navbar,
  Row,
  Col,
  Nav,
  NavDropdown,
  Container
} from "react-bootstrap";

import { Routes, Route, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";

import Dashboard from "./pages/Dashboard"
import DeviceCommunication from "./pages/DeviceCommunication"
import Database from "./pages/Database"
import Api from "./pages/Api"
import Alerts from "./pages/Alerts"
import Jobs from "./pages/Jobs"
import Dependencies from "./pages/Dependencies"
import DistributionSnapshot from './pages/DistributionSnapshot';
import InterfaceHealth from './pages/InterfaceHealth';
import { getrbac } from "./service/getdata"
import IWGatewayInterfaceHealth from './pages/IWGatewayInterfaceHealth';

function App({ pca }) {
  const navigate = useNavigate();
  const [useraccess, setuseraccess] = useState("");

  useEffect(() => {
    getrbac(pca?.getActiveAccount()?.username).then((d) => {
      if (JSON.stringify(d) !== "{}" && JSON.stringify(d?.data) !== "[]") {
        //console.log(JSON.parse(JSON.stringify(d?.data?.[0]["screens"])))
        setuseraccess(JSON.parse(JSON.stringify(d?.data?.[0]["screens"])))
      }
    });
  }, [useraccess,pca])

  const jobs = () => {
    //window.location.href = window.location.origin + "/jobs";
    navigate("/jobs")
  }

  const homepage = () => {
    navigate("/")
  }

  const refreshpage = () => {
    window.location.reload(false);
  }

  const logout = () => {
    pca.logoutPopup()
  }

  const checkaccess = (type, item) => {
    if (type === "menus" && useraccess !== "") {
      var js = JSON.parse(useraccess)
      var menuarr = js["UX"]["menus"]
      return menuarr.indexOf(item) === -1 ? "opdash-hidden" : "opdash-visible"
    }
    else return "opdash-visible"
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">
        {pca?.getActiveAccount()?.username}
      </Popover.Header>
      <Popover.Body>
        <ListGroup variant="flush">
          <ListGroup.Item className="m-0 p-0">
            <Button variant="white" size="sm" disabled>
              <FontAwesomeIcon className='text-dark' icon={solid("sliders")} />
              <label className="text-dark cnxt-font ps-2">Settings</label>
            </Button>
          </ListGroup.Item>
          <ListGroup.Item className="m-0 p-0">
            <Button variant="white" size="sm" onClick={logout}>
              <FontAwesomeIcon className='text-dark' icon={solid("arrow-right-from-bracket")} />
              <label className="text-dark cnxt-font ps-2">Logout</label>
            </Button>
          </ListGroup.Item>
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Navbar sticky='top' bg='secondary' className='text-light'>
          <Button variant="white" size="sm">
          </Button>
          <Navbar.Brand className="pull-left text-light  ml-5" onClick={homepage}>
            {/* <img src="https://v4-alpha.getbootstrap.com/assets/brand/bootstrap-solid.svg" width="30" height="30" class="d-inline-block align-top" alt="" /> */}
            Operational Insights
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="Operational Health Metrics" id="basic-nav-dropdown" className='text-light' className={checkaccess("menus", 1)}>
                <NavDropdown.Item href="/distributionsnapshot" >
                  <FontAwesomeIcon
                    className="align-middle"
                    icon={solid("building-circle-arrow-right")}
                    size="sm"
                  />
                  <label className="ps-1 od-font-sm" style={{ verticalAlign: 'baseline' }}>Toyota Distribution Snapshot & Health</label>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Technical Health Metrics" id="basic-nav-dropdown" className='text-light' className={checkaccess("menus", 2)}>
                <NavDropdown.Item href="/devicecommunication" >
                  <FontAwesomeIcon
                    className="align-middle"
                    icon={solid("microchip")}
                    size="sm"
                  />
                  <label className="ps-1 od-font-sm" style={{ verticalAlign: 'baseline' }}>Device Communication</label>
                </NavDropdown.Item>
                <NavDropdown.Item href="/api">
                  <FontAwesomeIcon
                    className="align-middle"
                    icon={solid("rss")}
                    size="sm"
                  />
                  <label className="ps-1 od-font-sm" style={{ verticalAlign: 'baseline' }}>API</label>
                </NavDropdown.Item>
                <NavDropdown.Item href="/database">
                  <FontAwesomeIcon
                    className="align-middle"
                    icon={solid("database")}
                    size="sm"
                  />
                  <label className="ps-1 od-font-sm" style={{ verticalAlign: 'baseline' }}>Database</label>
                </NavDropdown.Item>
                <NavDropdown.Item href="/alerts">
                  <FontAwesomeIcon
                    className="align-middle"
                    icon={solid("bell")}
                    size="sm"
                  />
                  <label className="ps-1 od-font-sm" style={{ verticalAlign: 'baseline' }}>Alerts</label>
                </NavDropdown.Item>
                <NavDropdown.Item href="/dependencies">
                  <FontAwesomeIcon
                    className="align-middle"
                    icon={solid("arrow-right")}
                    size="sm"
                  />
                  <label className="ps-1 od-font-sm" style={{ verticalAlign: 'baseline' }}>Dependencies</label>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Interface Health Metrics" id="basic-nav-dropdown" className='text-light' className={checkaccess("menus", 3)}>
                <NavDropdown.Item href="/interfacehealth">
                  <FontAwesomeIcon
                    className="align-middle"
                    icon={solid("file-waveform")}
                    size="sm"
                  />
                  <label className="ps-1 od-font-sm" style={{ verticalAlign: 'baseline' }}>External Interface Health</label>
                </NavDropdown.Item>
                <NavDropdown.Item href="/IWGatewayInterfaceHealth">
                  <FontAwesomeIcon
                    className="align-middle"
                    icon={solid("file-waveform")}
                    size="sm"
                  />
                  <label className="ps-1 od-font-sm" style={{ verticalAlign: 'baseline' }}>iW Gateway Interface Health</label>
                </NavDropdown.Item>
              </NavDropdown>
              {/* <Nav.Link className="text-light">
                <LinkContainer to="/devicecommunication">
                  <NavItem>
                    <FontAwesomeIcon
                      className="align-middle"
                      icon={solid("microchip")}
                      size="sm"
                    />
                    <label className="ps-1 od-font-sm" style={{ verticalAlign: 'baseline' }}>Device Communication</label>
                  </NavItem>
                </LinkContainer>
              </Nav.Link> */}
              {/* <Nav.Link className="text-light">
                <LinkContainer to="/api">
                  <NavItem>
                    <FontAwesomeIcon
                      className="align-middle"
                      icon={solid("rss")}
                      size="sm"
                    />
                    <label className="ps-1 od-font-sm" style={{ verticalAlign: 'baseline' }}>API</label>
                  </NavItem>
                </LinkContainer>
              </Nav.Link> */}
              {/* <Nav.Link className="text-light">
                <LinkContainer to="/database">
                  <NavItem>
                    <FontAwesomeIcon
                      className="align-middle"
                      icon={solid("database")}
                      size="sm"
                    />
                    <label className="ps-1 od-font-sm" style={{ verticalAlign: 'baseline' }}>Database</label>
                  </NavItem>
                </LinkContainer>
              </Nav.Link> */}
              {/* <Nav.Link className="text-light">
                <LinkContainer to="/alerts">
                  <NavItem>
                    <FontAwesomeIcon
                      className="align-middle"
                      icon={solid("bell")}
                      size="sm"
                    />
                    <label className="ps-1 od-font-sm" style={{ verticalAlign: 'baseline' }}>Alerts</label>
                  </NavItem>
                </LinkContainer>
              </Nav.Link> */}
              {/* <Nav.Link className="text-light">
                <LinkContainer to="/dependencies">
                  <NavItem>
                    <FontAwesomeIcon
                      className="align-middle"
                      icon={solid("arrow-right")}
                      size="sm"
                    />
                    <label className="ps-1 od-font-sm" style={{ verticalAlign: 'baseline' }}>Dependencies</label>
                  </NavItem>
                </LinkContainer>
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
          {/* <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={hierarchyfilter}
          >
            <Button variant="white" size="lg">
              <FontAwesomeIcon icon={solid("filter")} />
            </Button>
          </OverlayTrigger> */}

          <Button variant="white" size="lg" onClick={jobs}>
            <FontAwesomeIcon icon={solid("gear")} />
          </Button>
          <Button variant="white" size="lg" onClick={refreshpage}>
            <FontAwesomeIcon icon={solid("refresh")} />
          </Button>
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={popover}
          >
            <Button variant="white" size="lg">
              <FontAwesomeIcon icon={solid("user")} />
            </Button>
          </OverlayTrigger>

        </Navbar>
        <Container fluid>
            <Routes>
              <Route path="/" element={<Dashboard pca={pca} />} />
              <Route path="/devicecommunication" element={<DeviceCommunication />} />
              <Route path="/database" element={<Database />} />
              <Route path="/api" element={<Api />} />
              <Route path="/alerts" element={<Alerts />} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/dependencies" element={<Dependencies />} />
              <Route path="/distributionsnapshot" element={<DistributionSnapshot pca={pca} />} />
              <Route path="/interfacehealth" element={<InterfaceHealth />} />
              <Route path="/IWGatewayInterfaceHealth" element={<IWGatewayInterfaceHealth />} />
            </Routes>
        </Container>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}
export default App;
